import React from 'react';

import Layout from '../components/Layout';

const IndexPage = ({ location }) => (
  <Layout
    location={location}
    title={'Impressum'}
    description={`Benso GmbH, Burgweg 44 3270 Aarberg | info@benso.ch`}
  >
    <article id="main">
      <header>
        <h2>Impressum</h2>
        <p></p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Kontaktadresse</h3>
          <p>
            Benso GmbH
            <br /> Burgweg 44
            <br />
            3270 Aarberg
            <br />
            Schweiz
            <br />
            info@benso.ch
          </p>
          <h3>Vertretungsberechtigte Personen</h3>
          <p>
            Dominik Schmid, Geschäftsführer
            <br />
            Jan Heimberg, Geschäftsführer
          </p>
          <h3>Handelsregistereintrag</h3>
          <p>
            Eingetragener Firmenname: Benso GmbH
            <br />
            Nummer: CHE-268.171.271
          </p>
          <h3>Mehrwertsteuernummer</h3>
          <p>CHE-268.171.271 MWST</p>
          <hr />
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
